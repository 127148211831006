import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"

const containerStyle = {
  width: "100%",
  height: "50vh",
  minHeight: "300px",
}

const position = { lat: 39.98346, lng: -74.864629 }

function GoogleMapApi() {
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const center = new window.google.maps.LatLng(position)
    map.panTo(center)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <LoadScript googleMapsApiKey="AIzaSyAQGc5SW0KXNFG7rtRgUZGFEK5-E8cHNJo">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={position} />
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(GoogleMapApi)
