import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import GoogleMap from "../components/googleMap"

import MailIcon from "../images/icons/email.svg"
import PhoneIcon from "../images/icons/phone.svg"
import HomeIcon from "../images/icons/home.svg"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h1 className="text-3xl lg:text-4xl text-center pb-6">
      Please contact me at
    </h1>
    <div className="flex flex-col items-center">
      <PhoneIcon className="w-5 h-5" />
      <p className="text-center leading-loose pb-6">
        <a
          href="tel:+1 (732) 647-6969"
          className="text-blue hover:text-blue-dark"
        >
          +1 (732) 647-6969
        </a>
      </p>
      <MailIcon className="w-5 h-5" />
      <p className="text-center leading-loose pb-6">
        lenkalazorikova@yahoo.com
      </p>
      <HomeIcon className="w-5 h-5" />
      <p className="text-center leading-loose pb-6">
        158 Fleetwood Ave
        <br />
        Mount Laurel, NJ 08054
        <br />
        <a href="#map" className="text-blue hover:text-blue-dark">
          See map below
        </a>
      </p>
    </div>

    <ContactForm />

    <section id="map" className="pt-10 absolute left-0 right-0">
      <GoogleMap />
    </section>
  </Layout>
)
export default ContactPage
