import React, { useCallback, useState, useRef } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Formik } from "formik"
import Axios from "axios"

const ContactForm = ({}) => {
  const [status, setStatus] = useState()
  const recaptchaRef = useRef(null)

  const executeCaptcha = useCallback(async () => {
    const token = await recaptchaRef.current.executeAsync()
    return token
  }, [recaptchaRef])

  return (
    <Formik
      initialValues={{ name: "", email: "", message: "", _gotcha: "" }}
      validate={values => {
        const errors = {}
        if (!values.name) {
          errors.name = "Required"
        }
        if (!values.email) {
          errors.email = "Required"
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address"
        }
        if (!values.message) {
          errors.message = "Required"
        }
        return errors
      }}
      onSubmit={async (values, { resetForm }) => {
        const token = await executeCaptcha()

        var formData = new FormData()
        formData.append("name", values.name)
        formData.append("email", values.email)
        formData.append("message", values.message)
        formData.append("_gotcha", values._gotcha)
        formData.append("g-recaptcha-response", token)

        // TODO: read from env / move to config
        const URL = "https://pianolessonsmoorestown.com/contact.php"

        await Axios.post(URL, formData)
          .then(response => {
            if (response.data && response.data.status === "SUCCESS") {
              console.log(response)
              resetForm({ name: "", email: "", message: "", _gotcha: "" })
              setStatus("SUCCESS")
            } else {
              console.error(response.data.message)
              setStatus("ERROR")
            }
          })
          .catch(error => {
            console.error(error)
            setStatus("ERROR")
          })
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          onSubmit={handleSubmit}
          className="bg-yellow-light-a30 max-w-xl mx-auto shadow rounded-sm p-6"
        >
          <label htmlFor="name" className="block pb-1">
            Your name:
            <input
              id="name"
              type="text"
              name="name"
              placeholder="John Smith"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              className={`bg-transparent focus:outline-none focus:border-blue border-b w-full py-1 ${
                errors.name && touched.name ? "border-red" : "border-gray-light"
              }`}
            />
          </label>
          {errors.name && touched.name ? (
            <div className="text-sm text-red pb-2">{errors.name}</div>
          ) : null}
          <label htmlFor="email" className="block pt-4 pb-1">
            Your e-mail:
            <input
              id="email"
              type="email"
              name="email"
              placeholder="john.smith@gmail.com"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className={`bg-transparent focus:outline-none focus:border-blue border-b w-full py-1 ${
                errors.email && touched.email
                  ? "border-red"
                  : "border-gray-light"
              }`}
            />
          </label>
          {errors.email && touched.email ? (
            <div className="text-sm text-red pb-2">{errors.email}</div>
          ) : null}
          <label htmlFor="message" className="block pt-4 pb-1">
            Your message:
            <textarea
              id="message"
              name="message"
              placeholder="Hi, I just have a few questions..."
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              rows="4"
              className={`bg-transparent focus:outline-none focus:border-blue border-b w-full py-1 ${
                errors.message && touched.message
                  ? "border-red"
                  : "border-gray-light"
              }`}
            />
          </label>
          {errors.message && touched.message ? (
            <div className="text-sm text-red pb-2">{errors.message}</div>
          ) : null}
          <input
            type="text"
            name="_gotcha"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._gotcha}
            className="hidden"
            placeholder="This field is required."
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdDzOAZAAAAADB-pNJroLgL0_xqdZUkEJlAuiTD"
          />
          {status === "SUCCESS" ? (
            <p className="bg-green text-white text-center p-2 text-lg rounded">
              Thank you, your message has been sent.
            </p>
          ) : null}
          {status === "ERROR" ? (
            <p className="text-red text-center pt-2">
              Ooops! There was an error.
            </p>
          ) : null}
          <button
            type="submit"
            disabled={isSubmitting}
            className="bg-orange w-full text-center hover:bg-orange-dark text-white shadow py-2 mt-4"
          >
            {isSubmitting ? "Sending..." : "Send "}
          </button>
        </form>
      )}
    </Formik>
  )
}

export default ContactForm
